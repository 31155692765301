body
{
    margin: 0;
    padding: 0;
    line-height: 1;
    background-color: #f5f6f8; 
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
ul 
{
    list-style: none;
}
.grecaptcha-badge
{
    display: none !important;
}
a:-webkit-any-link
{
    text-decoration: none;
}
/* QUILL JS */
.ql-video
{
    width: 560px;
    height: 315px;
}
.ql-toolbar.ql-snow 
{
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}
.ql-container.ql-snow 
{
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}
.ql-container.ql-snow, .ql-toolbar.ql-snow 
{
    border: 1px solid rgba(0, 0, 0, 0.23);
}
.ql-editor.ql-blank::before 
{
    font-style: normal;
    color: #a2a2a2;
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 1.1875em;
}
/* END QUILL JS */